






























































.MenuStyle .box {
  padding: 1rem 1rem 0;
  position: relative;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
  img {
    width: 25.1rem;
    height: 10.6rem;
  }
  .btn {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    color: #333333;
    text-align: center;
    border-radius: 50%;
    background-color: #E7E7E7;
    border:1px solid rgba(191, 191, 191, 1);
    cursor: pointer;
  }
  .bottom {
    border-top: 0.1rem dashed #aaa;
    padding-top: 1rem;
  }
}
.box::after {
  position: absolute;
  top: 50%;
  width: 80%;
  content: "";
  padding: 2rem 0;
}
