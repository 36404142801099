.CourseTablekponit /deep/ .el-radio .el-radio__label {
  display: none;
}
.CourseTablekponit /deep/ .el-radio .el-radio__inner {
  width: 18px;
  height: 18px;
  border-color: #777;
}
.CourseTablekponit /deep/ .el-radio .el-radio__inner:hover {
  border-color: #5c6be8;
}
.CourseTablekponit /deep/ .el-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #5c6be8;
  background-color: #5c6be8;
}
